import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd,ActivatedRoute } from '@angular/router';
import { environment } from  '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map,first } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CorporateService } from '../../service/corporate.service';
import { AuthencticateService } from '../../service/authencticate.service';
declare let $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public profileComplete : Observable<any>;
  public ispopUpShow : Observable<any>;
  isLoggedIn = false;
  isCorporateLoggedIn = false;
  sessionUserId ='';
  sessionFirstName ='';
  sessionLastName ='';
  cdnUrl:any;
  saveRefData:any;
  env = environment;
  crptData:any;
  walletAmount:any;
  creditAmount:any;
  corporateLogo:any;
  
  
  constructor(
	  private http: HttpClient,
      private router : Router, 
      private route: ActivatedRoute,
	  private corporateService: CorporateService,
    private AuthencticateService: AuthencticateService
    ){ }

  ngOnInit() {
    this.profileComplete = this.AuthencticateService.getMyGV();
	  /* alert(localStorage.getItem('corporateUser'));
	   if(localStorage.getItem('corporateUser')){
			sessionStorage.setItem('corporateUser',localStorage.getItem('corporateUser'));
	   } */
	this.walletAmount=0;
	this.creditAmount=0;
	this.corporateLogo='';
	
	if(localStorage.getItem('corporateUser')){
	   this.corporateService.getCorporateData(JSON.parse(localStorage.getItem('corporateUser')).Data.customer_id)
	   .pipe(first())
	   .subscribe(data => {
			this.crptData = data['Data'];
				 	    
			sessionStorage.setItem('walletAmount', this.crptData.wallet_amount);
			sessionStorage.setItem('creditAmount', this.crptData.symbol+this.crptData.credit_amount);
			sessionStorage.setItem('corporateLogo', this.crptData.corporate_logo);
			
			this.walletAmount=sessionStorage.getItem('walletAmount');
			this.creditAmount=sessionStorage.getItem('creditAmount');
			if(sessionStorage.getItem('corporateLogo')){
				this.corporateLogo=sessionStorage.getItem('corporateLogo');
			}
			
			
		});
	}
	
	
	if(localStorage.getItem('currentUser')){
	   this.corporateService.getCorporateData(JSON.parse(localStorage.getItem('currentUser')).Data.customer_id)
	   .pipe(first())
	   .subscribe(data => {
			this.crptData = data['Data'];
			this.walletAmount=this.crptData.wallet_amount;
		});
	}
	
	
	  
    this.cdnUrl=environment.cdnUrl;
	
    if(localStorage.getItem('currentUser')){
      this.isLoggedIn =true;
    }
	
	if(localStorage.getItem('corporateUser')){
      this.isCorporateLoggedIn =true;
    }
	
	$(".menuIcon").click(function(){
		$(".navigation-panel").slideToggle();
	});
	
	//this.referrer();
  }
  
  
  logout() {
  
    this.AuthencticateService.setMyPopVar(false);
   
  if(localStorage.getItem('corporateUser')){
	this.router.navigate(['/corporate/login']).then(() => {
      window.location.reload();
    });
  } else{
	this.router.navigate(['/customer/login']).then(() => {
      window.location.reload();
    });
  }

    localStorage.removeItem('currentUser');
    sessionStorage.removeItem('corporateUser');
	localStorage.removeItem('corporateUser');

  
    
  }
  
  referrer(){
	  /* var pathurl=window.location.href;
	  
		  
	  this.http.post<any>(`${environment.apiUrl}/cms/webSiteVisiter`, {pathurl}).subscribe(res => {
		return res;
	  }); */
	  
	  
	  	  
	
   /*  this.saveRefData = {
		referrer: document.referrer
    };
   
    this.visaService
      .saveAppliction(this.saveRefData)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data['Code'] == '200') {
            this.toastr.success('Success', 'Personal details modified successfully');
            //console.log(data['Data'].Count_data);
            if (data['Data'].Count_data == 1) {
              this.router.navigate(['/order-summary/' + localStorage.getItem('refNo')], { relativeTo: this.route });
            } else {
              setTimeout(() => {
                window.location.reload();
              }, 100);
            }
          } else {
          }
        },
        (error) => { }
      );
	console.log(initreferrer); */
  }
  

}
