import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VisaService {
	public mySummaryVar = new BehaviorSubject<any>("");
  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute) { }


	setMySV(val : string){
        this.mySummaryVar.next(val);
    }

    getMySV(){
        return this.mySummaryVar.asObservable();
    }


	getCurrency(){
      return this.http.post<any>(`${environment.apiUrl}/Visa/getAlltblCurrencies`, {}).pipe(map(currencyData => {
		    return currencyData;
      }));
    }


	getVisaTypeByDestination(destination_id: string){
      return this.http.post<any>(`${environment.apiUrl}/Visa/visaTypeBydestinationIdRepeat`, {destination_id}).pipe(map(visatypes => {
		    return visatypes;
      }));
    }


    getVisaType(nationality_id: string,living_in: string,destination_id: string){
      return this.http.post<any>(`${environment.apiUrl}/Visa/visaType`, {nationality_id,living_in,destination_id}).pipe(map(visatypes => {
		    return visatypes;
      }));
    }

    getVisaTypeSticker(nationality_id: string,living_in: string,destination_id: string){
      return this.http.post<any>(`${environment.apiUrl}/Visa/visaTypeSticker`, {nationality_id,living_in,destination_id}).pipe(map(visatypes => {
		    return visatypes;
      }));
    }

    getVisaTypeLanding(nationality_id: string,living_in: string,destination_id: string){
      return this.http.post<any>(`${environment.apiUrl}/Visa/visaTypeBydestinationId`, {nationality_id,living_in,destination_id}).pipe(map(visatypes => {
        return visatypes;
      }));
    }

    getAdditionalServiceLanding(nationality_id: string,living_in: string,destination_id: string,visa_type_id:string){
      return this.http.post<any>(`${environment.apiUrl}/Visa/visaTypeBydestinationId`, {nationality_id,living_in,destination_id,visa_type_id}).pipe(map(visatypes => {
        return visatypes;
      }));
    }

    getAdditionalServiceSticker(nationality_id: string,living_in: string,destination_id: string,visa_type_id:string){
      return this.http.post<any>(`${environment.apiUrl}/Visa/GetStickerAdditionalServiceTypeBydestinationId`, {nationality_id,living_in,destination_id,visa_type_id}).pipe(map(visatypes => {
        return visatypes;
      }));
    }

	 getVisaTypeCurrency(nationality_id: string,living_in: string,destination_id: string,currency_id:any){
      return this.http.post<any>(`${environment.apiUrl}/Visa/visaType`, {nationality_id,living_in,destination_id,currency_id}).pipe(map(visatypes => {
		    return visatypes;
      }));
    }

    getDestinationWiseNAL(destination_id: string){
      return this.http.post<any>(`${environment.apiUrl}/Visa/getDestinationWiseNAL`, {destination_id}).pipe(map(visatypes => {
		    return visatypes;
      }));
    }



	getTurkeyCity() {
        return this.http.get<any>(`${environment.apiUrl}/customer/turkeyEntryCity`).pipe(map(cityData => {
            return cityData;
        }));
    }

    getTurkeyCityDistrict(id:any) {
        return this.http.get<any>(`${environment.apiUrl}/customer/turkeyEntryDistrict/${id}`).pipe(map(districtData => {
            return districtData;
        }));
    }



    saveAppliction(applData: any){
      return this.http.post<any>(`${environment.apiUrl}/Visa/saveAppliction`, {applData}).pipe(map(appform => {
		    return appform;
      }));
    }

    saveRepeatAppliction(applData: any){
      return this.http.post<any>(`${environment.apiUrl}/Visa/saveRepeatAppliction`, {applData}).pipe(map(appform => {
		    return appform;
      }));
    }

    saveTravelApplication(applData: any){
      return this.http.post<any>(`${environment.apiUrl}/Visa/saveTravelApplication`, {applData}).pipe(map(appform => {
        return appform;
      }));
    }


	saveTurkeyEntryForm(applData: any){
      return this.http.post<any>(`${environment.apiUrl}/Visa/saveApplictionDrop`, {applData}).pipe(map(appform => {
		    return appform;
      }));
    }


	saveApplictionDrop(applData: any){
      return this.http.post<any>(`${environment.apiUrl}/Visa/saveApplictionDrop`, {applData}).pipe(map(appform => {
		    return appform;
      }));
    }

    saveTravelApplicationDrop(applData: any){
      return this.http.post<any>(`${environment.apiUrl}/Visa/saveTravelApplicationDrop`, {applData}).pipe(map(appform => {
        return appform;
      }));
    }



 	 deleteAppliction(applData: any){
      return this.http.post<any>(`${environment.apiUrl}/Visa/deleteAppliction`, applData).pipe(map(deleteData => {
         return deleteData;
      }));
    }

    visaOrderSummary(get_data: any){
      return this.http.post<any>(`${environment.apiUrl}/Visa/summary`, get_data).pipe(map(orderSummary => {
        return orderSummary;
      }));
    }

    generateOrder(reference_no: any,payment_gateway:any,coupon_applied:any,coupon_code:any,lang:any,customer_id:any,payment_type,isWalletChecked:any,isPayUsing:any){

      return this.http.post<any>(`${environment.apiUrl}/Payment/generateOrder`, {reference_no,payment_gateway,coupon_applied,coupon_code,lang,customer_id,payment_type,isWalletChecked,isPayUsing}).pipe(map(genOrder => {
        return genOrder;
      }));
    }
    generateOrderStrip(reference_no: any,payment_gateway:any,coupon_applied:any,coupon_code:any,lang:any,tokan:any,customer_id:any,payment_type,isWalletChecked:any,isPayUsing:any){
      return this.http.post<any>(`${environment.apiUrl}/Payment/generateOrder`, {reference_no,payment_gateway,coupon_applied,coupon_code,lang,tokan,customer_id,payment_type,isWalletChecked,isPayUsing}).pipe(map(genOrder => {
        return genOrder;
      }));
    }
    generateOrderCCav(reference_no: any,payment_gateway:any,coupon_applied:any,coupon_code:any,lang:any,customer_id:any,payment_type,isWalletChecked:any,isPayUsing:any){
      return this.http.post<any>(`${environment.apiUrl}/Payment/generateOrder`, {reference_no,payment_gateway,coupon_applied,coupon_code,lang,customer_id,payment_type,isWalletChecked,isPayUsing}).pipe(map(genOrder => {
        return genOrder;
      }));
    }
    gPayconfirmOrder(reference_no: any,payment_gateway:any,coupon_applied:any,coupon_code:any,lang:any,tokan:any,google_pay_Order:any,customer_id:any,payment_type,isWalletChecked:any,isPayUsing:any){
      return this.http.post<any>(`${environment.apiUrl}/Payment/gPayconfirmOrder`, {reference_no,payment_gateway,coupon_applied,coupon_code,lang,tokan,google_pay_Order,customer_id,payment_type,isWalletChecked,isPayUsing}).pipe(map(genOrder => {
        return genOrder;
      }));
    }

    gPayconfirmOrderPaymentLinkExtra(reference_no: any,payment_gateway:any,lang:any,tokan:any,google_pay_Order:any,payment_type:any){
      return this.http.post<any>(`${environment.apiUrl}/Payment/gPayconfirmOrder`, {reference_no,payment_gateway,lang,tokan,google_pay_Order,payment_type}).pipe(map(genOrder => {
        return genOrder;
      }));
    }

    confirmOrder(orderDetails:any){
      return this.http.post<any>(`${environment.apiUrl}/Payment/confirmOrder`, orderDetails).pipe(map(genOrder => {
        return genOrder;
      }));
    }

    orderResponse(order_id:any){
      return this.http.post<any>(`${environment.apiUrl}/Payment/orderResponse`, {order_id}).pipe(map(genOrder => {
        return genOrder;
      }));
    }

    applyCouponCode(reference_no:any,coupon_code:any,lang:any){
      return this.http.post<any>(`${environment.apiUrl}/Payment/applyCouponCode`, {reference_no,coupon_code,lang}).pipe(map(genOrder => { return genOrder;
      }));
    }

    paymentCheck(order_id:any,ref:any,status:any,tran_ref:any,cdate:any,lang:any){
      return this.http.post<any>(`${environment.apiUrl}/Payment/check_payment`, {order_id,ref,tran_ref,status,cdate,lang}).pipe(map(genOrder => { return genOrder;
      }));
    }

    visaOrderSummaryExtra(get_data: any){
      return this.http.post<any>(`${environment.apiUrl}/Visa/extraPaymentSummary`, get_data).pipe(map(orderSummary => {
        return orderSummary;
      }));
    }

    generateOrderExtra(reference_no: any,payment_gateway:any,extra_payment_id:any,payment_type:any){
      return this.http.post<any>(`${environment.apiUrl}/Payment/generateOrder`, {reference_no,payment_gateway,extra_payment_id,payment_type}).pipe(map(genOrder => {
        return genOrder;
      }));
    }

    generateOrderExtraStrip(reference_no: any,payment_gateway:any,extra_payment_id:any,payment_type:any,tokan:any){
      return this.http.post<any>(`${environment.apiUrl}/Payment/generateOrder`, {reference_no,payment_gateway,extra_payment_id,payment_type,tokan}).pipe(map(genOrder => {
        return genOrder;
      }));
    }
	generateOrderExtraCcav(reference_no: any,payment_gateway:any,extra_payment_id:any,payment_type:any,tokan:any){
      return this.http.post<any>(`${environment.apiUrl}/Payment/generateOrder`, {reference_no,payment_gateway,extra_payment_id,payment_type,tokan}).pipe(map(genOrder => {
        return genOrder;
      }));
    }


    confirmOrderExtra(orderDetails:any){
      return this.http.post<any>(`${environment.apiUrl}/Payment/extraConfirmOrder`, orderDetails).pipe(map(genOrder => {
        return genOrder;
      }));
    }

    orderResponseExtra(order_id:any){
      return this.http.post<any>(`${environment.apiUrl}/Payment/extraOrderResponse`, {order_id}).pipe(map(genOrder => {
        return genOrder;
      }));
    }



	visaOrderSummaryPaymentLinkExtra(get_data: any){
      return this.http.post<any>(`${environment.apiUrl}/Visa/extraPaymentSummaryLink`, get_data).pipe(map(orderSummary => {
        return orderSummary;
      }));
    }

    generateOrderPaymentLinkExtra(reference_no: any,payment_gateway:any,extra_payment_id:any,payment_type:any){
      return this.http.post<any>(`${environment.apiUrl}/Payment/generateOrder`, {reference_no,payment_gateway,extra_payment_id,payment_type}).pipe(map(genOrder => {
        return genOrder;
      }));
    }
    generateOrderPaymentLinkExtraStrip(reference_no: any,payment_gateway:any,extra_payment_id:any,payment_type:any,tokan:any){
      return this.http.post<any>(`${environment.apiUrl}/Payment/generateOrder`, {reference_no,payment_gateway,extra_payment_id,payment_type,tokan}).pipe(map(genOrder => {
        return genOrder;
      }));
    }
	generateOrderPaymentLinkExtraCcav(reference_no: any,payment_gateway:any,extra_payment_id:any,payment_type:any,tokan:any){
      return this.http.post<any>(`${environment.apiUrl}/Payment/generateOrder`, {reference_no,payment_gateway,extra_payment_id,payment_type,tokan}).pipe(map(genOrder => {
        return genOrder;
      }));
    }

    confirmOrderPaymentLinkExtra(orderDetails:any){
      return this.http.post<any>(`${environment.apiUrl}/Payment/extraConfirmOrder`, orderDetails).pipe(map(genOrder => {
        return genOrder;
      }));
    }

    orderResponsePaymentLinkExtra(order_id:any){
      return this.http.post<any>(`${environment.apiUrl}/Payment/extraOrderResponse`, {order_id}).pipe(map(genOrder => {
        return genOrder;
      }));
    }


    getLocalStorageData(reference_no:any){
      return this.http.post<any>(`${environment.apiUrl}/Visa/getApplictionForm`, {reference_no}).pipe(map(localStorageData => {
        return localStorageData;
      }));
    }


	getFeaturedImage(destinationId: any){
      return this.http.post<any>(`${environment.apiUrl}/Visa/getFeaturedImage`, {destinationId}).pipe(map(faqs => {
            return faqs;
      }));
    }



	getDestinationContents(destinationId: any){
      return this.http.post<any>(`https://api.instaglobaltravel.com/customer/destinationContents`, {destinationId}).pipe(map(destinationContents => {
            return destinationContents;
      }));
    }



	//Wallet
	AddMoneyToWallet(get_data: any){
      return this.http.post<any>(`${environment.apiUrl}/addmoney/summary`, get_data).pipe(map(orderSummary => {
        return orderSummary;
      }));
    }


	generateOrderMoneyToWallet(payment_gateway:any,lang:any,customer_id:any,payment_type:any,amount:any){
      return this.http.post<any>(`${environment.apiUrl}/Addmoney/generateOrder`, {payment_gateway,lang,customer_id,payment_type,amount}).pipe(map(genOrder => {
        return genOrder;
      }));
    }


	generateOrderMoneyToWalletCCav(payment_gateway:any,lang:any,customer_id:any,payment_type:any,amount:any){
      return this.http.post<any>(`${environment.apiUrl}/Addmoney/generateOrder`, {payment_gateway,lang,customer_id,payment_type,amount}).pipe(map(genOrder => {
        return genOrder;
      }));
    }


	generateOrderMoneyToWalletStripe(payment_gateway:any,lang:any,tokan:any,customer_id:any,payment_type:any,amount:any){
      return this.http.post<any>(`${environment.apiUrl}/Addmoney/generateOrder`, {payment_gateway,lang,tokan,customer_id,payment_type,amount}).pipe(map(genOrder => {
        return genOrder;
      }));
    }

	gPayconfirmOrderMoneyToWallet(payment_gateway:any,lang:any,tokan:any,google_pay_Order:any,customer_id:any,payment_type:any,amount:any){
      return this.http.post<any>(`${environment.apiUrl}/Addmoney/gPayconfirmOrder`, {payment_gateway,lang,tokan,google_pay_Order,customer_id,payment_type,amount}).pipe(map(genOrder => {
        return genOrder;
      }));
    }

	confirmOrderMoneyToWallet(orderDetails:any){
      return this.http.post<any>(`${environment.apiUrl}/Addmoney/confirmOrder`, orderDetails).pipe(map(genOrder => {
        return genOrder;
      }));
    }

	orderWalletResponse(order_id:any){
      return this.http.post<any>(`${environment.apiUrl}/Addmoney/orderResponse`, {order_id}).pipe(map(genOrder => {
        return genOrder;
      }));
    }

	paymentWalletCheck(order_id:any,ref:any,status:any,tran_ref:any,cdate:any,lang:any){
      return this.http.post<any>(`${environment.apiUrl}/Addmoney/check_payment`, {order_id,ref,tran_ref,status,cdate,lang}).pipe(map(genOrder => { return genOrder;
      }));
    }




    getEncryptVal(for_encrypt:any,lang:any){
      return this.http.post<any>(`${environment.apiUrl}/Visa/getEncryptVal`, {for_encrypt,lang}).pipe(map(genOrder => { return genOrder;
      }));
    }


	  getFaqsByDestination(lang: string,destinationId: string,nationalityId: string,faqId: string){
      return this.http.post<any>(`${environment.apiUrl}/faq/getFaq`, {lang,destinationId,nationalityId,faqId}).pipe(map(faqs => {
            return faqs;
      }));
    }

    getServiceTypes(){
      return this.http.get<any>(`${environment.apiUrl}/customer/serviceTypes`).pipe(map(serviceTypes => {
        return serviceTypes;
      }));
    }

    updateCustomerInterestedService(applData: any){
      return this.http.post<any>(`${environment.apiUrl}/Visa/updateCustomerInterestedService`, {applData}).pipe(map(appform => {
		    return appform;
      }));
    }
}