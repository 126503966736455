import { Component,OnInit, Input, Output} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
//import { HelperService } from './service/helper.service';
import { VisaService } from './service/visa.service';

@Component({
  selector: 'body',
  template:"<app-header></app-header><app-loader></app-loader><router-outlet></router-outlet><app-footer></app-footer>"
  // templateUrl: './app.component.html',
  // styleUrls: ['./app.component.css'],
  //providers:[HelperService]
})
export class AppComponent {
  amount: number = 500;
  title = 'globaltravel';
  loading = false;
  fShow:any;
  constructor(
  private router: Router,
  public visaService: VisaService){
	  
	  
  } 
 ngOnInit() {
   this.router.events.subscribe((evt) => {
     if (!(evt instanceof NavigationEnd)) {
       return;
     }
	 
	 
	 if(evt instanceof NavigationEnd) {
		
		
            if(evt.url.indexOf('order-summary')>0) {
				this.visaService.setMySV("");
				
            }
			else{
				this.visaService.setMySV("footerShow");
				

			
			}
        } 
		
		
     window.scrollTo(0, 0);
   });
   
   
   
 }
 deposit(){
  this.amount +=100;
}
}
